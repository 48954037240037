import React from 'react';
import clsx from 'clsx';
import Participant from '../Participant/Participant';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import { RemoteParticipant } from 'twilio-video';
import { useAppState } from '../../state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridModeContainer: {
      padding: '0.5em',
      overflowY: 'auto',
      background: '#012',
      gridArea: '1 / 2 / 1 / 4',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(500px, 1fr))',
      gridTemplateRows: 'repeat(auto-fit, minmax(250px, 1fr))',
      gridColumnGap: '0.5em',
      zIndex: 5,

      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',

      [theme.breakpoints.down('sm')]: {
        gridArea: '2 / 1 / 3 / 3',
        overflowY: 'initial',
        overflowX: 'auto',
        display: 'flex',
        padding: '8px',
      },
    },
    speakerModeContainer: {
      padding: '2em',
      overflowY: 'auto',
      background: 'rgb(79, 83, 85)',
      gridArea: '1 / 3 / 1 / 4',
      zIndex: 5,
    },
    transparentBackground: {
      background: 'transparent',
    },
    scrollContainer: {
      [theme.breakpoints.down('sm')]: {
        display: 'grid',
      },
    },
  })
);

export default function ParticipantList() {
  const classes = useStyles();
  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const { settings } = useAppState();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const mainParticipant = useMainParticipant();
  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;

  // if (participants.length === 0) return null; // Don't render this component if there are no remote participants.

  const _localParticipant = () => {
    return <Participant participant={localParticipant} isLocalParticipant={true} />;
  };

  const _remoteParticipant = (participant: RemoteParticipant, hideParticipant: boolean) => {
    return (
      <Participant
        key={participant.sid}
        participant={participant}
        isSelected={participant === selectedParticipant}
        onClick={() => setSelectedParticipant(participant)}
        hideParticipant={hideParticipant}
      />
    );
  };

  const gridMode = () => {
    return (
      <div
        className={clsx(classes.gridModeContainer, {
          [classes.transparentBackground]: !isRemoteParticipantScreenSharing,
        })}
      >
        {participants.map(participant => {
          return _remoteParticipant(participant, false);
        })}
        {_localParticipant()}
      </div>
    );
  };

  const speakerMode = () => {
    if (participants.length === 0) return null;
    return (
      <aside
        className={clsx(classes.speakerModeContainer, {
          [classes.transparentBackground]: !isRemoteParticipantScreenSharing,
        })}
      >
        <div className={classes.scrollContainer}>
          {_localParticipant()}
          {participants.map(participant => {
            const isSelected = participant === selectedParticipant;
            const hideParticipant =
              participant === mainParticipant && participant !== screenShareParticipant && !isSelected;
            return _remoteParticipant(participant, hideParticipant);
          })}
        </div>
      </aside>
    );
  };

  return settings.viewMode == 'speaker' ? speakerMode() : gridMode();
}
