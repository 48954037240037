import { useState, useCallback } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';
import { LUMIN_BASE_URL, getAuthHeaders } from '../../state/useLuminAuth/useLuminAuth';

enum CALL_STATE {
  Inactive,
  Calling,
  Failed,
  NoAnswer,
  Connected,
}

export default function useLuminSignalling() {
  const { room, onError } = useVideoContext();
  const [callState, setCallState] = useState(CALL_STATE.Inactive);

  const sendInvite = useCallback(
    luminAccount => {
      const url: string = `${LUMIN_BASE_URL}/api/v2/push/voip/${luminAccount.contact_uuid}?command=connect&room_name=${room.name}`;
      const options = {
        method: 'get',
        headers: getAuthHeaders(),
      };

      return fetch(url, options)
        .then(response => setCallState(CALL_STATE.Calling))
        .catch(response => setCallState(CALL_STATE.Failed));
    },
    [room]
  );

  return { sendInvite, callState } as const;
}
