import React, { useEffect, useState } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';

import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../state';
import useLuminParticipants from '../../hooks/useLuminParticipants/useLuminParticipants';
import LuminAccountListItem from './LuminAccountListItem/LuminAccountListItem';
import { LuminAccount } from '../../state/useLuminAccounts/useLuminAccounts';
import useLuminSignalling from '../../hooks/useLuminSignalling/useLuminSignalling';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      gridArea: 'menu',
      minWidth: 250,
      color: 'white',
      width: '10%',
      gridColumnStart: 1,
      gridRowStart: 1,
      overflow: 'scroll',
      backgroundColor: '#404040',
      [theme.breakpoints.down('xs')]: {
        bottom: `${theme.sidebarMobileHeight + 3}px`,
      },
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5),0px 5px 0px 0px rgba(0,0,0,0.14),0px 10px 0px 0px rgba(0,0,0,0.12)',
      display: 'flex',
      flexDirection: 'column',
    },
    participantsListingContainer: {
      overflow: 'hidden',
      maxHeight: 500,
      minHeight: 150,
      display: 'flex',
      flexDirection: 'column',
    },
    participantslisting: {
      overflowY: 'scroll',
      maxHeight: 500,
    },
    invitedParticipantsListingContainer: {
      overflow: 'hidden',
      maxHeight: 500,
      minHeight: 150,
      display: 'flex',
      flexDirection: 'column',
    },
    invitedParticipantsListing: {
      overflowY: 'scroll',
      maxHeight: 500,
    },
    inviteListingContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    inviteListing: {
      overflowY: 'scroll',
    },
    barHeader: {
      marginLeft: 8,
      marginVertical: 12,
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
  })
);

export default function InviteBar() {
  const classes = useStyles();
  const roomState = useRoomState();
  const { activeParticipants, invitedParticipants, inviteParticipant } = useLuminParticipants();
  const isReconnecting = roomState === 'reconnecting';
  const showControls = roomState === 'disconnected';
  const { jwt, luminAccounts, isFetchingAccounts, getAccounts } = useAppState();

  const { sendInvite } = useLuminSignalling();

  // useEffect(() => {
  //   if (jwt) {
  //     getAccounts();
  //   }
  // }, [jwt]);

  const inviteLuminUser = (luminAccount: LuminAccount) => {
    inviteParticipant(luminAccount);
    sendInvite(luminAccount);
    return '';
  };

  const activeParticipantsListing = () => {
    if (activeParticipants.length < 1) {
      return;
    } else {
      return (
        <div className={clsx(classes.participantsListingContainer)}>
          <h2 className={clsx(classes.barHeader)}>
            Connected <span>({luminAccounts.length})</span>
          </h2>
          <div className={clsx(classes.participantslisting)}>
            {activeParticipants?.map(luminAccount => {
              return (
                <LuminAccountListItem
                  key={luminAccount.contact_uuid}
                  luminAccount={luminAccount}
                  showInviteButton={false}
                  sendInvite={inviteLuminUser}
                />
              );
            })}
          </div>
        </div>
      );
    }
  };

  const inviteListing = () => {
    const inactiveAccounts = luminAccounts?.filter(
      la => !activeParticipants.includes(la) && !invitedParticipants.includes(la)
    );
    return (
      <div className={clsx(classes.inviteListingContainer)}>
        <h2 className={clsx(classes.barHeader)}>
          Lumin Users <span>{!isFetchingAccounts && `(${luminAccounts.length})`}</span>
        </h2>
        {isFetchingAccounts && <CircularProgress className={classes.loadingSpinner} />}
        <div className={clsx(classes.inviteListing)}>
          {inactiveAccounts?.map(luminAccount => {
            return (
              <LuminAccountListItem luminAccount={luminAccount} showInviteButton={true} sendInvite={inviteLuminUser} />
            );
          })}
        </div>
      </div>
    );
  };

  const invitedParticipantsListing = () => {
    if (invitedParticipants.length < 1) {
      return;
    } else {
      return (
        <div className={clsx(classes.invitedParticipantsListingContainer)}>
          <h2 className={clsx(classes.barHeader)}>Calling...</h2>
          <div className={clsx(classes.invitedParticipantsListing)}>
            {invitedParticipants?.map(luminAccount => {
              return (
                <LuminAccountListItem
                  luminAccount={luminAccount}
                  showInviteButton={false}
                  sendInvite={inviteLuminUser}
                />
              );
            })}
          </div>
        </div>
      );
    }
  };

  return (
    <div className={clsx(classes.container)}>
      {activeParticipantsListing()}
      {invitedParticipantsListing()}
      {inviteListing()}
    </div>
  );
}
