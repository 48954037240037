import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const LUMIN_BASE_URL: string = 'https://api.mylumin.net';
export const APP_URL: string = 'https://videochat.prod.mylumin.net';

export interface LuminAdminUser {
  first_name: string;
  last_name: string;
  displayName: string | undefined;
  photoURL: undefined;
  passcode: undefined;
  email: string;
}

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export function getJWT() {
  return window.sessionStorage.getItem('jwt');
}

export function getAuthHeaders() {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getJWT()}`,
  };
}

export async function login(email: string, password: string) {
  const url = LUMIN_BASE_URL + '/api/v1/sessions';

  const data = JSON.stringify({
    user_type: 'community_admin',
    email: email,
    password: password,
  });

  const response = await fetch(url, {
    method: 'POST',
    headers: headers,
    body: data,
  });

  const ok = response.ok;
  const formatted_response = await response.json();

  if (ok && formatted_response.token) {
    return formatted_response.token;
  } else {
    throw new Error(formatted_response.error);
  }
}

export async function getAuthorizationURL() {
  const callback_url = `${APP_URL}/oauth_callback`;
  const url = LUMIN_BASE_URL + `/oauth/auth_url?redirect_uri=${callback_url}`;

  const response = await fetch(url, {
    method: 'get',
    headers: headers,
  });
  const ok = response.ok;
  const formatted_response = await response.json();
  if (ok && formatted_response.auth_url) {
    return formatted_response.auth_url;
  } else {
    throw new Error(formatted_response.error);
  }
}

export async function getAccessToken(code: string) {
  const url = LUMIN_BASE_URL + '/oauth/callback';
  const callback_url = `${APP_URL}/oauth_callback`;
  const data = JSON.stringify({
    redirect_uri: callback_url,
    code: code,
  });

  const response = await fetch(url, {
    method: 'POST',
    headers: headers,
    body: data,
  });

  const ok = response.ok;
  const formatted_response = await response.json();
  if (ok && formatted_response) {
    return formatted_response.access_token;
  } else {
    throw new Error(formatted_response.error);
  }
}

export function fetchToken() {
  const url: string = LUMIN_BASE_URL + `/api/v1/community/twilio_access_token`;

  return fetch(url, {
    method: 'get',
    headers: getAuthHeaders(),
  }); //.then( (response: any) => response.data.token )
}

export function getErrorMessage(message: string) {
  switch (message) {
    case 'passcode incorrect':
      return 'Passcode is incorrect';
    case 'passcode expired':
      return 'Passcode has expired';
    default:
      return message;
  }
}

function fetchAdminUser() {
  const url = LUMIN_BASE_URL + '/api/v1/community_admin';
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getJWT()}`,
  };

  return fetch(url, {
    method: 'get',
    headers: headers,
  })
    .then((response: any) => {
      return response.json();
    })
    .catch(error => {
      console.log('ERROR.........', error);
      if (error.response) {
        throw new Error(error.response.data.error);
      } else {
        throw new Error('API Error');
      }
    });
}

export default function useLuminAuth() {
  const history = useHistory();

  const [jwt, setJWT] = useState<string | null>(null);
  const [user, setUser] = useState<LuminAdminUser | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);

  const getToken = useCallback(() => {
    return fetchToken()
      .then(response => response.json())
      .then(response => {
        return response.token as string;
      });
  }, []);

  useEffect(() => {
    const jwt = getJWT();

    if (jwt) {
      setJWT(jwt);
      if (!user) {
        fetchAdminUser().then(adminUser => {
          adminUser.displayName = adminUser.first_name + ' ' + adminUser.last_name;
          setUser(adminUser as LuminAdminUser);
        });
      }
      history.replace(window.location.pathname);
      setIsAuthReady(true);
    } else {
      setIsAuthReady(true);
    }
  }, [history]);

  const signIn = useCallback(async (passcode: string) => {
    const jwt = await getAccessToken(passcode);
    window.sessionStorage.setItem('jwt', jwt);
    setJWT(jwt);
    if (!user) {
      fetchAdminUser().then(adminUser => {
        adminUser.displayName = adminUser.first_name + ' ' + adminUser.last_name;
        setUser(adminUser as LuminAdminUser);
      });
    }
    setIsAuthReady(true);
    history.replace({ pathname: '/' });
    return Promise.resolve();
  }, []);

  const signOut = useCallback(() => {
    setUser(null);
    window.sessionStorage.removeItem('jwt');
    return Promise.resolve();
  }, []);

  return { jwt, user, isAuthReady, getToken, signIn, signOut };
}
