import React, { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useLocation, Redirect } from 'react-router-dom';
import { useAppState } from '../../state';

const OauthCallback = () => {
  const location = useLocation<{ from: Location }>();
  const { signIn } = useAppState();

  useEffect(() => {
    const code = (location.search.match(/code=([^&]+)/) || [])[1];
    signIn?.(code);
  }, []);

  return <CircularProgress />;
};

export default OauthCallback;
