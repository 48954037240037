import { useCallback, useEffect, useState } from 'react';

import { getJWT } from '../useLuminAuth/useLuminAuth';

const LUMIN_BASE_URL: string = 'https://api.mylumin.net';

export interface LuminAccount {
  id: string;
  uuid: string;
  contact_uuid: string;
  full_name: string;
  name: string;
  status: string;
  username: string;
  email: string;
  phone_number: string;
  time_zone: string;
  image_url: string;
  large_image_url: string;
  square_image_url: string;
  thumb_image_url: string;
}

export async function fetchAccounts() {
  const url: string = LUMIN_BASE_URL + `/api/v1/accounts`;

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getJWT()}`,
  };

  const response = await fetch(url, {
    method: 'get',
    headers: headers,
  });

  const formatted_response = await response.json();
  if (response.ok) {
    return formatted_response;
  } else {
    throw new Error(formatted_response.error);
  }
}

export default function useLuminAccounts() {
  const [luminAccounts, setLuminAccounts] = useState<LuminAccount[]>([]);
  const [hasFetchedAccounts, setHasFetchedAccounts] = useState(false);
  const [isFetchingAccounts, setIsFetchingAccounts] = useState(true);

  const getAccounts = useCallback(async () => {
    setIsFetchingAccounts(true);
    try {
      const accounts = await fetchAccounts();
      const activeAccounts = accounts.filter((a: LuminAccount) => a.status == 'active');
      setLuminAccounts(activeAccounts);
      setHasFetchedAccounts(true);
      setIsFetchingAccounts(false);
    } catch (err) {
      console.log('ERROR fetching accounts: ', err);
    }
  }, []);

  const findAccount = (id: string) => {
    return luminAccounts.find(a => a.contact_uuid == id);
  };

  return { luminAccounts, isFetchingAccounts, hasFetchedAccounts, getAccounts, findAccount };
}
