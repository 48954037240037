import { useEffect, useState } from 'react';
import { RemoteParticipant } from 'twilio-video';
import useDominantSpeaker from '../useDominantSpeaker/useDominantSpeaker';
import useVideoContext from '../useVideoContext/useVideoContext';
import { LuminAccount } from '../../state/useLuminAccounts/useLuminAccounts';
import { useAppState } from '../../state';

export default function useLuminParticipants() {
  const { findAccount } = useAppState();

  const { room } = useVideoContext();
  const dominantSpeaker = useDominantSpeaker();
  const [activeParticipants, setActiveParticipants] = useState(Array<LuminAccount>());
  const [invitedParticipants, setInvitedParticipants] = useState(Array<LuminAccount>());

  const inviteParticipant = (luminAccount: LuminAccount) => {
    setInvitedParticipants(prevParticipants => [...prevParticipants, luminAccount]);
    setTimeout(() => {
      setInvitedParticipants(prevParticipants => prevParticipants.filter(p => p.uuid !== luminAccount.uuid));
    }, 60000);
  };

  useEffect(() => {
    const participantConnected = (participant: RemoteParticipant) => {
      const matchingAccount = findAccount(participant.identity);
      if (matchingAccount) {
        setInvitedParticipants(prevParticipants => prevParticipants.filter(p => p.uuid !== matchingAccount.uuid));
        setActiveParticipants(prevParticipants => [...prevParticipants, matchingAccount]);
      }
    };

    const participantDisconnected = (participant: RemoteParticipant) =>
      setActiveParticipants(prevParticipants => prevParticipants.filter(p => p.contact_uuid !== participant.identity));

    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);

    return () => {
      room.off('participantConnected', participantConnected);
      room.off('participantDisconnected', participantDisconnected);
    };
  }, [room]);

  return { activeParticipants, invitedParticipants, inviteParticipant };
}
