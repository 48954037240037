import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import AccountAvatar from '../AccountAvatar/AccountAvatar';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import clsx from 'clsx';

import useRoomState from '../../../hooks/useRoomState/useRoomState';

import { LuminAccount } from '../../../state/useLuminAccounts/useLuminAccounts';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 40,
    paddingLeft: 8,
    paddingRight: 5,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'black',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    padding: 8,
  },
});

export default function LuminAccountListItem({
  luminAccount,
  showInviteButton,
  sendInvite,
}: {
  luminAccount: LuminAccount;
  showInviteButton: boolean;
  sendInvite: (luminAccount: LuminAccount) => {};
}) {
  const classes = useStyles();
  const roomState = useRoomState();

  // const { sendInvite, callState } = useLuminSignalling();

  const { full_name, square_image_url, thumb_image_url } = luminAccount!;

  return (
    <div key={luminAccount.id} className={clsx(classes.container)}>
      <div className={clsx(classes.nameContainer)}>{luminAccount.full_name}</div>

      {showInviteButton && (
        <IconButton
          className={classes.button}
          color="inherit"
          onClick={() => {
            if (roomState == 'connected') {
              sendInvite(luminAccount);
            } else {
              alert(`cannot invite. The room state is ${roomState}`);
            }
          }}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      )}
    </div>
  );
}
