import React, { useEffect, useState } from 'react';

import { useAppState } from '../../state';
import useLuminParticipants from '../../hooks/useLuminParticipants/useLuminParticipants';
import { LuminAccount } from '../../state/useLuminAccounts/useLuminAccounts';

import ParticipantList from '../ParticipantList/ParticipantList';
import { styled } from '@material-ui/core/styles';
import MainParticipant from '../MainParticipant/MainParticipant';
import InviteBar from '../InviteBar/InviteBar';

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: `250px 1fr ${theme.sidebarWidth}px`,
  gridTemplateRows: '100%',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: `100%`,
    gridTemplateRows: `1fr ${theme.sidebarMobileHeight + 16}px`,
  },
}));

export default function Room() {
  const { jwt, luminAccounts, isFetchingAccounts, getAccounts, settings } = useAppState();

  useEffect(() => {
    if (jwt) {
      getAccounts();
    }
  }, [jwt]);

  if (isFetchingAccounts && luminAccounts.length < 1) {
    return <div>Loading</div>;
  }
  return (
    <Container>
      <InviteBar />
      {settings.viewMode == 'speaker' && <MainParticipant />}
      <ParticipantList />
    </Container>
  );
}
